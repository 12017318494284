import { TextField as MUITextField, TextFieldProps } from "@mui/material";
import React from "react";

export default function TextField2({
    size = "small",
    onKeyDownCapture = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent the default behavior of Enter key
            const n = document.getElementById((e.target as HTMLInputElement).id)?.parentElement
                ?.parentElement?.nextElementSibling?.children[1]?.children[0] as HTMLInputElement;
            if (n) n.focus();
        }
    },
    tfRef,
    innerState = false,
    ...rest
}: TCustomFieldProps) {
    const [state, setState] = React.useState(rest.value);

    React.useEffect(() => {
        if (innerState && rest.value !== state) {
            setState(rest.value);
        }
    }, [rest.value]);

    return (
        <MUITextField
            size={size}
            ref={tfRef}
            onKeyDownCapture={onKeyDownCapture}
            {...rest}
            value={innerState ? state : rest.value}
            onChange={(e) => {
                if (innerState) {
                    setState(e.target.value);
                } else {
                    rest.onChange && rest.onChange(e);
                }
            }}
            onBlur={(e) => {
                rest.onBlur && rest.onBlur(e);
                if (innerState) {
                    setState(e.target.value);
                    rest.onChange && rest.onChange(e);
                }
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
}

export type TCustomFieldProps = TextFieldProps & {
    tfRef?;
    innerState?;
};
