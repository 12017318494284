import { useEffect, useRef, useState } from "react";
import Modal from "../../app2/Modal";
import { TTabsContainerApiRef } from "../../App/NavTabs/TabsContainer";
import { TKeyBind, TTabOption } from "../../App/AppContainer";
import Button from "../../app2/Button";
import TextField2 from "../../app2/TextField2";
import { useAlert } from "../../../providers/AlertProvider";

type TKeyBindManagerModalProps = {
    apiRef: React.MutableRefObject<TKeyBindManagerApi>;
    tabsContainerApiRef: React.MutableRefObject<TTabsContainerApiRef>;
    tabOptions: TTabOption[];
    keyBinds: TKeyBind[];
    setKeyBinds: React.Dispatch<React.SetStateAction<TKeyBind[]>>;
};
export type TKeyBindManagerApi = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    scrollToPage: (page: string) => void;
};
export default function KeyBindManagerModal(props: TKeyBindManagerModalProps) {
    const alert = useAlert();
    const [show, setshow] = useState(false);

    props.apiRef.current = {
        setShow: setshow,
        scrollToPage: (page) => {},
    };

    const [bindsList, setbindsList] = useState<TKeyBind[]>([
        ...defualtBinds.filter((e) => {
            return !props.keyBinds.some((el) => el.action === e.action && el.page === e.page);
        }),
        ...props.keyBinds,
    ]);

    const handleBindChange = (page, action, field, value) => {
        setbindsList((prev) => {
            return prev.map((el) => {
                if (el.action === action && el.page === page) {
                    return { ...el, [field]: value };
                }
                return el;
            });
        });
    };

    useEffect(() => {
        setbindsList([
            ...defualtBinds.filter((e) => {
                return !props.keyBinds.some((el) => el.action === e.action && el.page === e.page);
            }),
            ...props.keyBinds,
        ]);
    }, [show, props.keyBinds]);

    if (!show) {
        return <></>;
    }
    return (
        <Modal
            title={"Keyboard Shortcuts Manager"}
            show={show}
            setshow={setshow}
            disableBackdropClick={true}
            keepMounted={false}
            id="keybinds-manager"
            width="90vw"
            height="90vh"
        >
            <div className="flex h-full w-full  p-1 ">
                <div className="flex h-full  flex-[4] flex-col px-4 ">
                    <div className="w-full">
                        <div className="grid w-full grid-cols-4 justify-items-center  pl-10 pr-1 text-lg font-semibold ">
                            <div className="w-full text-center">Action</div>
                            <div className="w-full text-center">Key</div>
                            <div className="w-full text-center">Ctrl</div>
                            <div className="w-full text-center">Alt</div>
                        </div>
                    </div>
                    <div className="flex h-full  w-full flex-col gap-5 overflow-y-auto  ">
                        {pageOptions.map((el, idx) => {
                            return (
                                <div className="  " key={idx}>
                                    <div className=" text-lg font-semibold" id={el.key}>
                                        # {el.label}
                                    </div>
                                    <div className="flex flex-col gap-1 pl-10 pr-1">
                                        {bindsList
                                            .filter((e) => e.page === el.key)
                                            .sort((a, b) => {
                                                return a.action.localeCompare(b.action);
                                            })
                                            .map((bind, idx) => {
                                                return (
                                                    <div
                                                        className="  grid grid-cols-4 items-center gap-1"
                                                        key={idx}
                                                    >
                                                        <div className="">{bind.action}</div>
                                                        <div>
                                                            <TextField2
                                                                value={bind.key.toUpperCase()}
                                                                onChange={(e) => {
                                                                    handleBindChange(
                                                                        bind.page,
                                                                        bind.action,
                                                                        "key",
                                                                        e.target.value.toUpperCase()
                                                                    );
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                                onFocus={(e) => {
                                                                    e.target.select();
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <TextField2
                                                                type="number"
                                                                value={bind.is_ctrl}
                                                                onChange={(e) => {
                                                                    handleBindChange(
                                                                        bind.page,
                                                                        bind.action,
                                                                        "is_ctrl",
                                                                        parseInt(e.target.value) > 0
                                                                            ? 1
                                                                            : 0
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    e.target.select();
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <TextField2
                                                                type="number"
                                                                value={bind.is_alt}
                                                                onChange={(e) => {
                                                                    handleBindChange(
                                                                        bind.page,
                                                                        bind.action,
                                                                        "is_alt",
                                                                        parseInt(e.target.value) !== 0
                                                                            ? 1
                                                                            : 0
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    e.target.select();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className=" flex  h-full flex-[1] flex-col p-2">
                    <div className="flex h-[50%] w-full flex-col gap-2 overflow-y-auto">
                        {pageOptions.map((el, idx) => {
                            return (
                                <div className="text-lg font-semibold " key={idx}>
                                    <div
                                        className=" cursor-pointer"
                                        onClick={(e) => {
                                            document
                                                .getElementById(el.key)
                                                ?.scrollIntoView({ behavior: "smooth" });
                                        }}
                                    >
                                        # {el.label}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex h-[50%] w-full flex-col gap-2 overflow-y-auto">
                        <Button
                            theme={"error"}
                            onClick={() => {
                                setbindsList(defualtBinds);
                            }}
                        >
                            Restore Defaults
                        </Button>
                        <Button
                            theme={"success"}
                            onClick={() => {
                                const url = "settings/save_keybinding/";
                                const cbnf = (res) => {
                                    props.setKeyBinds(bindsList);
                                };

                                alert.postdata(
                                    url,
                                    cbnf,
                                    {
                                        binds: bindsList,
                                    },
                                    true
                                );
                            }}
                        >
                            save
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
const pageOptions = [
    {
        key: "goods",
        label: "Goods",
    },
    {
        key: "invoice",
        label: "Invoice",
    },
    {
        key: "statmentOfItem",
        label: "Statment Of Item",
    },
    {
        key: "stockOverview",
        label: "Stock Overview",
    },
    // {
    //     key: "accounts",
    //     label: "Accounts",
    // },
    {
        key: "voucher",
        label: "Voucher",
    },
    {
        key: "statmentOfAccount",
        label: "Statment Of Account",
    },
    {
        key: "trialBalance",
        label: "Trial Balance",
    },
];

const defualtBinds: TKeyBind[] = [
    {
        page: "goods",
        action: "addItem",
        key: "F1",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "goods",
        action: "searchItem",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "goods",
        action: "itemAttributes",
        key: "F3",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "addItem",
        key: "F1",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "createInvoice",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "deleteSelectedItems",
        key: "F3",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "duplicateInvoice",
        key: "F4",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "post/accounting",
        key: "F5",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "printInvoice",
        key: "F6",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "showImport/Export",
        key: "F7",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "showInvoiceList",
        key: "L",
        is_ctrl: 1,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "openSerialNumberModal",
        key: "3",
        is_ctrl: 1,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "showItemsManagment",
        key: "F9",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "showPayInvoice",
        key: "F10",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "showPriceList",
        key: "F8",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "toggleSelection",
        key: "T",
        is_ctrl: 1,
        is_alt: 0,
    },
    {
        page: "invoice",
        action: "openItemCard",
        key: "I",
        is_ctrl: 1,
        is_alt: 0,
    },
    {
        page: "voucher",
        action: "printVoucher",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "voucher",
        action: "showTransaction",
        key: "F1",
        is_ctrl: 0,
        is_alt: 0,
    },
    // {
    //     page: "voucher",
    //     action: "createVoucher",
    //     key: "F3",
    //     is_ctrl: 0,
    //     is_alt: 0,
    // },
    {
        page: "voucher",
        action: "deleteVoucher",
        key: "F4",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfItem",
        action: "requestStatment",
        key: "F1",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfItem",
        action: "backToFilters",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfItem",
        action: "exportAsCsv",
        key: "F5",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "stockOverview",
        action: "requestStock",
        key: "F1",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "stockOverview",
        action: "resetFilters",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "stockOverview",
        action: "backToFilters",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "stockOverview",
        action: "printReport",
        key: "F4",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "stockOverview",
        action: "exportAsCsv",
        key: "F5",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfAccount",
        action: "requestStatment",
        key: "F1",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfAccount",
        action: "resetFilters",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfAccount",
        action: "backToFilters",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfAccount",
        action: "showPrintModal",
        key: "F3",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "statmentOfAccount",
        action: "exportAsCsv",
        key: "F4",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "trialBalance",
        action: "requestBalances",
        key: "F1",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "trialBalance",
        action: "resetFilters",
        key: "F2",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "trialBalance",
        action: "showPrintModal",
        key: "F3",
        is_ctrl: 0,
        is_alt: 0,
    },
    {
        page: "trialBalance",
        action: "exportAsCsv",
        key: "F4",
        is_ctrl: 0,
        is_alt: 0,
    },
];
