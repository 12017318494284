import { TSelectedTab } from "../AppContainer";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "../../app2/Button";
import { useContextMenuApi } from "../../../providers/ContextMenuProvider";

export default function Tabs(props: TTabsProps) {
    const handleTabClick = () => {
        props.setSelectedTab(props.tab);
        props.tab.onTabClick && props.tab.onTabClick();
    };
    const cmApi = useContextMenuApi();
    const contextMenuHandler = (e) => {
        cmApi.current.menuApi.current.handleShowMenu(
            [
                {
                    label: "Close All But This",
                    onClick: () => {
                        props.setOpenedTabs([props.tab]);
                        props.setSelectedTab(props.tab);
                    },
                },
                {
                    label: "Close All Tabs",
                    onClick: () => {
                        props.setOpenedTabs([]);
                    },
                },
            ],
            e
        );
    };
    return (
        <Button
            key={props.tab.id + props.tab.value}
            className={`flex h-[2rem] w-full min-w-[7rem] cursor-pointer select-none flex-row items-center justify-between gap-4 whitespace-nowrap  p-2 text-center font-semibold `}
            theme={props.selectedTab.id + props.selectedTab.value === props.tab.id + props.tab.value ? "success" : "primary"}
            onClick={handleTabClick}
            onContextMenu={contextMenuHandler}
        >
            <div className="mt-auto flex w-full flex-row items-center justify-center whitespace-nowrap px-2  font-semibold">
                {props.tab.title}
                {props.tab.id > 1 ? ` (${props.tab.id})` : ""}
            </div>
            <div>
                <IconButton
                    size="small"
                    sx={{ p: 0, zIndex: 1000 }}
                    onClick={(e) => {
                        props.tab.onClose && props.tab.onClose();
                        e.stopPropagation();
                        let temp: TSelectedTab[] = [];
                        props.setOpenedTabs((old) => {
                            temp = old.filter((el) => {
                                return !(el.id === props.tab.id && el.value === props.tab.value);
                            });
                            return temp;
                        });
                        temp[temp.length - 1] && props.setSelectedTab(temp[temp.length - 1]);
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </Button>
    );
}
type TTabsProps = {
    tab: TSelectedTab;
    selectedTab: TSelectedTab;
    setSelectedTab: React.Dispatch<React.SetStateAction<TSelectedTab>>;
    setOpenedTabs: React.Dispatch<React.SetStateAction<TSelectedTab[]>>;
};
