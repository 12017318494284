import { Button as MUIButton } from "@mui/material";
import React from "react";
import { useLanguage } from "./LanguageContext";
import { ExtendButtonBase } from "@mui/material/ButtonBase";
import { ButtonTypeMap } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";

export default function Button({
    variant = "outlined",
    onClick = () => {},
    size = "small",
    children,
    theme = "primary",
    color = "black",
    sx = {},
    minWidth = "7rem",
    disabled = false,
    id = "",
    autoFocus = false,
    onBlur = () => {},
    apiRef = React.useRef(),
    btnRef,
    ...props
}: any | ExtendButtonBase<ButtonTypeMap>) {
    if (theme === "success") {
        color = "";
    }
    if (theme === "error") {
        color = "";
    }
    let tempSx = {};
    if (variant === "outlined") {
        tempSx = {
            color: `button.bgColor.${color}`,
            borderColor: `button.bgColor.${color}`,
            ":hover": {
                backgroundColor: "#00000017",
                borderWidth: "2px",
                borderColor: `button.bgColor.${color}`,
            },
        };
    } else if (variant === "contained") {
        tempSx = { color: `button.color.primary`, backgroundColor: `button.bgColor.blue` };
    }
    const { lang } = useLanguage();
    const fontFamily = (lang) => {
        if (lang === "en") {
            return "revert";
        } else if (lang === "ar") {
            return "system-ui";
        }
    };
    const [loading, setLoading] = React.useState(false);

    apiRef.current = {
        setLoading,
    };
    return (
        <>
            {loading ? (
                <LoadingSpinner show={true} />
            ) : (
                <MUIButton
                    autoFocus={autoFocus}
                    id={id}
                    variant={variant}
                    size={size}
                    color={theme}
                    onClick={onClick}
                    onBlur={onBlur}
                    sx={{
                        ...sx,
                        minWidth: minWidth,
                        ...tempSx,
                        fontFamily: fontFamily(lang.lang),
                        fontWeight: "500",
                        borderWidth: "2px",
                    }}
                    disabled={disabled}
                    onContextMenu={props.onContextMenu}
                    ref={btnRef || null}
                >
                    {children}
                </MUIButton>
            )}
        </>
    );
}
