import axios from "axios";
import { getAuthData } from "../hooks/app/getAuthData";
import { AlertAPI } from "../providers/AlertProvider";
import React, { useContext } from "react";

const serversData = localStorage.getItem("serversData");
let serverHost = "127.0.0.1";
let serverPort = "8000";
if (serversData) {
    const serversDataLocalStorage = JSON.parse(serversData);
    const selectedServer = serversDataLocalStorage.selectedServer;
    if (selectedServer) {
        serverHost = selectedServer.host;
        serverPort = selectedServer.port;
    }
}

export const postData = (
    url: string,
    callback: (res: any) => any,
    tdata: any,
    showmsg: boolean,
    alertAPI: AlertAPI | null = null,
    onError: (err: any) => void = () => {}
) => {
    const { token, dataname } = getAuthData();
    const fdata = Object.assign({ token, dataname }, tdata);
    axios({
        url: `${serverPort === "443" ? "https" : "http"}://` + serverHost + `:${serverPort}/` + url,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: fdata,
    })
        .then((res) => {
            callback(res);
            const debug = localStorage.getItem("d");
            if (debug) {
                console.log(res);
            }
            if (res.data.info === "success") {
                if (showmsg) {
                    alertAPI?.showAlert(res.data.status, res.data.msg); // alertAPI.showAlert(res.data.status, res.data.msg);
                }
            } else {
                alertAPI.showAlert(res.data.status, res.data.msg); // alertAPI.showAlert(res.data.status, res.data.msg);
            }
        })
        .catch((err) => {
            alertAPI.showAlert("failed", err.message); // alertAPI.showAlert(res.data.status, res.data.msg);
        });
};

export async function asyncPostData(
    url: string,
    callback: (res: any) => any,
    tdata: any,
    showmsg: boolean,
    alertAPI: AlertAPI | null = null,
    onError: (err: any) => void = () => {}
) {
    const { token, dataname } = getAuthData();
    const fdata = Object.assign({ token, dataname }, tdata);
    try {
        const res = await axios({
            url:
                `${serverPort === "443" ? "https" : "http"}://` +
                serverHost.replace('"', "") +
                `:${serverPort}/` +
                url,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: fdata,
        });
        const debug = localStorage.getItem("d");
        if (debug) {
            console.log(res);
        }
        callback(res);
        if (res.data.info === "success") {
            if (showmsg) {
                alertAPI?.showAlert(res.data.status, res.data.msg);
            }
        } else {
            onError(res);
            alertAPI?.showAlert(res.data.status, res.data.msg);
        }
        return res;
    } catch (err) {
        console.log(err);
    }
}
