import React, { useEffect } from "react";
import AppTheme from "./components/app2/AppTheme";
import { LanguageProvider } from "./components/app2/LanguageContext";
import AlertProvider, { AlertAPI } from "./providers/AlertProvider";
import HomeScreen from "./Pages/BackOffice/HomeScreen";
import Box from "@mui/material/Box";
import AppContainer from "./components/App/AppContainer";
import { TUser, UserFuncProvider } from "./providers/UserFunctionsProvider";
import { GoodsFuncProvider } from "./providers/GoodsFunctionsProvider";
import { InvoiceFuncProvider } from "./providers/InvoiceFunctionsProvider";
import { TTabsContainerApiRef } from "./components/App/NavTabs/TabsContainer";
import { SystemFuncProvider } from "./providers/SystemFunctionsProvider";
import { AccountsFuncProvider } from "./providers/AccountsFunctionsProvider";
import { VouchersFuncProvider } from "./providers/VouchersFunctionsProvider";
import { TDataGridModalAPI } from "./components/app2/DataGridModal2";
import { ContextMenuApiProvider } from "./providers/ContextMenuProvider";
import { CRMFuncProvider } from "./providers/CRMFunctionsProvider";
import TokenModal from "./components/Others/TokenModel/TokenModal";

function App() {
    const [isLogedIn, setIsLogedIn] = React.useState<boolean>(false);
    const [alertAPI, setalertAPI] = React.useState<AlertAPI>({
        showAlert: () => {},
        hideAlert: () => {},
    });
    const [user, setUser] = React.useState<TUser>(null);
    const [initializing, setInitializing] = React.useState<boolean>(true);
    const [showTokenModal, setshowTokenModal] = React.useState<boolean>(false);

    const tabContainerApiRef = React.useRef<TTabsContainerApiRef>();
    const dataGridModalApiRef = React.useRef<TDataGridModalAPI>({});
    const theme = user?.settings?.system?.default?.use_dark_mode === 0 ? "light" : "dark";
    document.body.classList.add(theme);
    useEffect(() => {
        if (initializing) {
            const sd = localStorage.getItem("serversData");
            if (sd === undefined || sd === null) {
                const href = window.location.href;
                let host = window.location.host.split(":")[0];
                const protocle = location.protocol;
                let port;
                if (href.startsWith("https")) {
                    port = "443";
                } else {
                    port = "8000";
                }
                if (protocle == "file:") {
                    host = "127.0.0.1";
                }
                localStorage.setItem(
                    "serversData",
                    JSON.stringify({
                        serversList: [
                            {
                                host: host,
                                port: port,
                                token: "",
                            },
                        ],
                        selectedServer: {
                            host: host,
                            port: port,
                            token: "",
                        },
                    })
                );
                window.location.reload();
            } else {
                const sd = JSON.parse(localStorage.getItem("serversData"));
                if (!sd["selectedServer"]["token"] && sd["selectedServer"]["port"] === "443") {
                    setshowTokenModal(true);
                }
            }
            setInitializing(false);
        }
    }, [initializing]);

    return (
        <>
            <AppTheme theme={theme}>
                <LanguageProvider>
                    <AlertProvider alertAPI={alertAPI} setalertAPI={setalertAPI}>
                        <div
                            className={`flex h-[100vh] w-[100vw] items-start justify-start  `}
                            style={{ backgroundColor: "black" }}
                        >
                            <Box
                                className=" h-[100%] w-full overflow-y-hidden  "
                                sx={{ backgroundColor: "system.primaryBG", color: "text.secondary" }}
                            >
                                <ContextMenuApiProvider>
                                    <CRMFuncProvider>
                                        <VouchersFuncProvider>
                                            <AccountsFuncProvider
                                                tabContainerApiRef={tabContainerApiRef}
                                            >
                                                <GoodsFuncProvider
                                                    tabContainerApiRef={tabContainerApiRef}
                                                >
                                                    <InvoiceFuncProvider
                                                        tabContainerApiRef={tabContainerApiRef}
                                                    >
                                                        <UserFuncProvider user={user} setUser={setUser}>
                                                            <SystemFuncProvider
                                                                tabContainerApiRef={tabContainerApiRef}
                                                                dataGridModalApiRef={dataGridModalApiRef}
                                                            >
                                                                {initializing ? (
                                                                    <div className="flex h-full w-full items-center justify-center">
                                                                        <div className="spinner h-[50px] w-[50px] rounded-full border-x-2 "></div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {isLogedIn ? (
                                                                            <AppContainer
                                                                                tabContainerApiRef={
                                                                                    tabContainerApiRef
                                                                                }
                                                                                dataGridModalApiRef={
                                                                                    dataGridModalApiRef
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <HomeScreen
                                                                                setisLogedIn={
                                                                                    setIsLogedIn
                                                                                }
                                                                                setUser={setUser}
                                                                                showTokenModal={
                                                                                    showTokenModal
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                                <TokenModal
                                                                    show={showTokenModal}
                                                                />
                                                            </SystemFuncProvider>
                                                        </UserFuncProvider>
                                                    </InvoiceFuncProvider>
                                                </GoodsFuncProvider>
                                            </AccountsFuncProvider>
                                        </VouchersFuncProvider>
                                    </CRMFuncProvider>
                                </ContextMenuApiProvider>
                            </Box>
                        </div>
                    </AlertProvider>
                </LanguageProvider>
            </AppTheme>
        </>
    );
}

export default App;
