import DashboardIcon from "@mui/icons-material/Dashboard";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useRef } from "react";
import Menu from "@mui/material/Menu";
import Dashboard from "../../../Pages/Others/Dashboard";
import { Reorder } from "framer-motion";

import Tabs from "./Tabs";
import { TKeyBind, TSelectedTab, TTabOption } from "../AppContainer";
import { Box } from "@mui/material";
import { useUserFunctions } from "../../../providers/UserFunctionsProvider";
import Fade from "@mui/material/Fade";
import KeyBindManagerModal, {
    TKeyBindManagerApi,
} from "../../Common/KeyBindManager/KeyBindManagerModal";

export default function TabsContainer(props: TTabsContainerProps) {
    const [navAnchorEl, setNavAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mainAnchorEl, setMainAnchorEl] = React.useState<null | HTMLElement>(null);
    const navOpen = Boolean(navAnchorEl);
    const mainOpen = Boolean(mainAnchorEl);
    const handleNavClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNavAnchorEl(event.currentTarget);
    };
    const handleNavClose = () => {
        setNavAnchorEl(null);
    };
    const handleMainClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMainAnchorEl(event.currentTarget);
    };
    const handleMainClose = () => {
        setMainAnchorEl(null);
    };
    const handleNewTab = (tab: TTabOption) => {
        tab.onClick && tab.onClick();
        const sameTabs = props.openedTabs.filter((el) => el.value === tab.value);
        let newId = 0;
        sameTabs.forEach((el) => {
            if (el.id > newId) {
                newId = el.id;
            }
        });
        const newSelectedTab = { ...tab, id: newId + 1, index: props.openedTabs.length };
        props.setSelectedTab(newSelectedTab);
        props.setOpenedTabs([...props.openedTabs, newSelectedTab]);
        handleNavClose();
    };
    useEffect(() => {
        if (props.openedTabs.length === 0) {
            props.setSelectedTab({
                id: 1,
                title: "",
                value: "dashboard",
                category: "",
                Element: Dashboard,
                index: 0,
            });
        }
    }, [props.openedTabs]);

    props.tabContainerApiRef.current.addTab = handleNewTab;
    const userFunc = useUserFunctions();

    const programTypes: TProgramsTypes[] = [
        { name: "Inventory", lic: ["All", "backoffice"] },
        { name: "Accounting", lic: ["All", "backoffice"] },
        { name: "Payroll", lic: ["All", "payroll"] },
        // { name: "Production", lic: ["All", "production"] },
        { name: "CRM", lic: ["All", "CRM"] },
        { name: "Others", lic: ["All", "backoffice", "payroll", "production"] },
    ];

    const KeyBindManagerModalApiRef = useRef<TKeyBindManagerApi>(null);

    return (
        <div className=" flex   h-[3.5rem]  w-[100%] flex-row  items-center justify-center p-1 ">
            <Box
                className="flex h-full w-[100%] flex-row items-center justify-start gap-2  rounded  p-1 "
                sx={{ backgroundColor: "system.secondaryBG" }}
            >
                <div style={{ width: "3rem" }} className="flex items-center justify-center border-r-2  ">
                    <div
                        style={{
                            backgroundColor: props.selectedTab.value === "dashboard" ? "#4c4c4c" : "",
                        }}
                        className="mr-2 rounded-md "
                    >
                        <Tooltip title="DashBoard">
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    props.setSelectedTab({
                                        id: 1,
                                        title: "",
                                        value: "dashboard",
                                        category: "",
                                        Element: Dashboard,
                                        index: 0,
                                    });
                                }}
                            >
                                <DashboardIcon
                                    color={
                                        props.selectedTab.value === "dashboard" ? "primary" : "inherit"
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div
                    style={{ flex: 1 }}
                    className="flex flex-row items-center gap-2 overflow-y-auto whitespace-nowrap"
                >
                    <Reorder.Group
                        values={props.openedTabs}
                        axis="x"
                        onReorder={props.setOpenedTabs}
                        as="div"
                        className="flex flex-row items-center gap-2 overflow-y-clip whitespace-nowrap"
                    >
                        {props.openedTabs.map((tab) => {
                            return (
                                <Reorder.Item value={tab} key={tab.id + tab.value} as="div">
                                    <Tabs
                                        tab={tab}
                                        selectedTab={props.selectedTab}
                                        setSelectedTab={props.setSelectedTab}
                                        setOpenedTabs={props.setOpenedTabs}
                                    />
                                </Reorder.Item>
                            );
                        })}
                    </Reorder.Group>
                    <IconButton size="small" onClick={handleNavClick}>
                        <AddIcon />
                    </IconButton>
                    <Menu
                        TransitionComponent={Fade}
                        id="basic-menu"
                        sx={{ width: "min(70rem,100%)" }}
                        anchorEl={navAnchorEl}
                        open={navOpen}
                        onClose={handleNavClose}
                    >
                        <div className="flex h-[20rem] w-[min(70rem,100%)] flex-row  justify-start gap-4 overflow-x-auto p-2">
                            {programTypes.map((cat) => {
                                return (
                                    <div
                                        key={cat.name}
                                        className="flex w-full min-w-[10rem] flex-row justify-center rounded-lg border-2 "
                                    >
                                        <div className="flex w-full flex-col gap-1">
                                            <div className=" w-full border-b-2 text-center text-lg font-semibold">
                                                {cat.name}
                                            </div>
                                            <div className="flex w-full flex-col gap-2  whitespace-nowrap p-1">
                                                {props.tabOptions
                                                    .filter((el) => el.category === cat.name)
                                                    .map((el) => {
                                                        return (
                                                            <Box
                                                                key={el.value}
                                                                className=" hover:bg-neutral-300x hover:dark:bg-neutral-600x w-full cursor-pointer rounded-md p-1 font-semibold"
                                                                sx={{
                                                                    "&:hover": {
                                                                        backgroundColor: "#00000042",
                                                                    },
                                                                }}
                                                                id={el.value}
                                                                onClick={() => {
                                                                    if (
                                                                        userFunc.user.pc_info.lic.some(
                                                                            (e) => cat.lic.includes(e)
                                                                        )
                                                                    ) {
                                                                        handleNewTab(el);
                                                                    }
                                                                }}
                                                            >
                                                                {el.title}
                                                            </Box>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Menu>
                </div>
                <div style={{ width: "3rem" }} className="flex items-center justify-center border-l-2">
                    <IconButton size="small" onClick={handleMainClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu2"
                        anchorEl={mainAnchorEl}
                        open={mainOpen}
                        onClose={handleMainClose}
                    >
                        <div className="flex min-h-[5rem] w-[min(23rem,100%)] flex-col justify-start gap-2 overflow-x-auto rounded-lg p-2">
                            <div
                                className="bg-sys-sec-l text-cl-pr-l
                                 dark:bg-sys-sec-d dark:text-cl-pr-d hover:dark:bg-hover-d cursor-pointer rounded-md
                                  p-2"
                                onClick={() => {
                                    userFunc.getSettings({
                                        data: { pcname: userFunc.user.pc_info.data.system_name },
                                    });
                                }}
                            >
                                Update Settings
                            </div>
                            <div
                                className="bg-sys-sec-l text-cl-pr-l
                                 dark:bg-sys-sec-d dark:text-cl-pr-d hover:dark:bg-hover-d cursor-pointer rounded-md
                                  p-2"
                                onClick={() => {
                                    KeyBindManagerModalApiRef.current.setShow(true);
                                }}
                            >
                                Keyboard Shortcuts
                            </div>

                            <div
                                className="bg-sys-sec-l text-cl-pr-l
                                 dark:bg-sys-sec-d dark:text-cl-pr-d hover:dark:bg-hover-d cursor-pointer rounded-md
                                  p-2"
                                onClick={() => {
                                    const cl = document.body.classList;
                                    cl.toggle("dark");
                                }}
                            >
                                Togle Dark Mode
                            </div>
                            <div
                                className="bg-sys-sec-l text-cl-pr-l
                                 dark:bg-sys-sec-d dark:text-cl-pr-d hover:dark:bg-hover-d cursor-pointer rounded-md
                                  p-2"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Log Out
                            </div>
                        </div>
                    </Menu>
                </div>
            </Box>
            <KeyBindManagerModal
                apiRef={KeyBindManagerModalApiRef}
                tabsContainerApiRef={props.tabContainerApiRef}
                tabOptions={props.tabOptions}
                keyBinds={props.keyBinds}
                setKeyBinds={props.setKeyBinds}
            />
        </div>
    );
}

type TTabsContainerProps = {
    tabOptions: TTabOption[];
    openedTabs: TSelectedTab[];
    setOpenedTabs: React.Dispatch<React.SetStateAction<TSelectedTab[]>>;
    selectedTab: TSelectedTab;
    setSelectedTab: React.Dispatch<React.SetStateAction<TSelectedTab>>;
    tabContainerApiRef: React.MutableRefObject<TTabsContainerApiRef>;
    keyBinds: TKeyBind[];
    setKeyBinds: React.Dispatch<React.SetStateAction<TKeyBind[]>>;
};

export type TTabsContainerApiRef = {
    addTab?: (tab: TTabOption) => void;
    tabOptions: TTabOption[];
    selectedTab: TSelectedTab;
};

export type TProgramsTypes = {
    name: string;
    lic: string[];
};
