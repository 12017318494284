import {
    DataGrid,
    GridApiCommon,
    GridCellParams,
    DataGridProps,
    useGridApiRef,
    GridColDef,
} from "@mui/x-data-grid";
import React, { useRef, MutableRefObject, useEffect } from "react";
// Extend GridColDef to include onEnterNewLine
export type CustomGridColDef = GridColDef & {
    onEnterNewLine?: boolean;
};
export interface TCustomDataTableProps extends DataGridProps {
    enterDirection?: "Horizontal" | "Vertical";
    setSelectedRow?: (e: any) => void;
    setSelectedLines?: any;
    onFieldEdit?: (selectedRow: GridCellParams, v: any) => void;
    gridRef?: MutableRefObject<HTMLDivElement>;
    apiRef?: MutableRefObject<GridApiCommon>;
    id?;
    tabId?;
    columns: CustomGridColDef[];
    newLineFocusIdx?;
}

function DataTable({
    enterDirection = "Horizontal",
    setSelectedRow = (e: any) => {},
    setSelectedLines,
    onFieldEdit = (e, v) => {},
    gridRef = useRef(null),
    apiRef = useGridApiRef(),
    id = "",
    tabId = "",
    newLineFocusIdx = 1,
    ...props
}: TCustomDataTableProps) {
    const selectedRow = useRef<GridCellParams>();
    const selectedRowHandler = (e: any) => {
        setSelectedRow(e);
        selectedRow.current = e;
    };
    // useEffect(() => {
    //     console.log("useEffect");
    //     gridRef?.current?.addEventListener("focusin", (e: FocusEvent & { target: HTMLElement }) => {
    //         const target = e.target as HTMLElement;
    //         if (props.onCellFocus && target.id === "datagridcell") {
    //             //remove the last element from the array
    //             const row = Array.from(e.target.parentElement.children)
    //                 ?.slice(0, -1)
    //                 .map((el) => {
    //                     return el.firstChild.textContent;
    //                 });
    //             props.onCellFocus(e, row);
    //         }
    //     });
    //     return () => {
    //         gridRef?.current?.removeEventListener(
    //             "focusin",
    //             (e: FocusEvent & { target: HTMLElement }) => {}
    //         );
    //     };
    // }, [props.rows, props]);

    return (
        <DataGrid
            {...props}
            apiRef={apiRef}
            ref={gridRef}
            columnHeaderHeight={props.columnHeaderHeight || 45}
            rowHeight={props.rowHeight || 35}
            getRowClassName={
                props.getRowClassName ||
                ((e) => {
                    if (e.indexRelativeToCurrentPage % 2 !== 0) {
                        return "bg-[#0000001c]";
                    }
                })
            }
            showColumnVerticalBorder={props.showColumnVerticalBorder || true}
            showCellVerticalBorder={props.showCellVerticalBorder || true}
            onCellEditStart={(e) => {
                props.onCellEditStart && (props as any).onCellEditStart(e);
                selectedRowHandler(e);
            }}
            onCellClick={(e) => {
                props.onCellClick && (props as any).onCellClick(e);
                selectedRowHandler(e);
            }}
            rowBuffer={props.rowBuffer || 100}
            sx={{
                "& .MuiDataGrid-cell:focus": {
                    borderWidth: "1px !important",
                    borderColor: "#0d6efd !important",
                    outline: "3px auto #0d6efd",
                },
                "& .MuiDataGrid-withBorderColor": {
                    borderColor: "#333333",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    color: "dataTable.header.color",
                    fontWeight: "500",
                },
                "& .MuiDataGrid-columnHeader": {
                    // backgroundColor: "dataTable.header.backgroundColor",
                },
                // backgroundColor: "dataTable.root.backgroundColor",
                borderColor: "dataTable.root.borderColor",
                color: "dataTable.root.color",
                ...props.sx,
            }}
            slotProps={{
                cell: { id: "datagridcell" + tabId },
                ...props.slotProps,
            }}
            hideFooter={props.hideFooter || props.rows.length < 100}
            processRowUpdate={
                props.processRowUpdate ||
                ((e) => {
                    if (e[selectedRow.current.field] !== selectedRow.current.value) {
                        onFieldEdit(selectedRow.current, e[selectedRow.current.field]);
                    }
                    return e;
                })
            }
            // Handle errors during row update
            onProcessRowUpdateError={(e) => {}}
            // Determine if a row is selectable
            isRowSelectable={props.isRowSelectable || ((e) => true)}
            // Handle key down events on cells
            onCellKeyDown={(e, f) => {
                props.onCellKeyDown && props.onCellKeyDown(e, f, null);
                if (f.key === "Enter") {
                    if (enterDirection === "Horizontal") {
                        const thisField = props.columns.find((el) => el.field === e.field);
                        const thisRowIndex = apiRef.current.getAllRowIds().findIndex((el) => {
                            return el === props.getRowId(e.row);
                        });
                        f.preventDefault();
                        f.defaultMuiPrevented = true;
                        // If the current field is the last column, move to the next row or onEnterNewLine is true
                        if (
                            thisField.field === props.columns[props.columns.length - 1].field ||
                            thisField.onEnterNewLine === true
                        ) {
                            const nextRow = apiRef.current.getRowIdFromRowIndex(thisRowIndex + 1);
                            if (thisRowIndex === props.rows.length - 1) {
                                apiRef.current.setCellFocus(e.id, props.columns[0].field);
                            } else {
                                apiRef.current.setCellFocus(nextRow, props.columns[newLineFocusIdx]?.field);
                            }
                        } else {
                            // Move to the next column in the same row
                            apiRef.current.setCellFocus(
                                e.id,
                                props.columns[props.columns.findIndex((el) => el.field === e.field) + 1]
                                    .field
                            );
                        }
                    }
                }
            }}
            // Handle changes in column visibility
            onColumnVisibilityModelChange={(e) => {}}

            // columnVisibilityModel={}
        />
    );
    function getInitialVisibility() {}
}

export default DataTable;

// ||
// thisField.editable === false ||
// props.columns[props.columns.findIndex((el) => el.field === e.field) + 1].editable === false
