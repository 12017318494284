import logo from "../../media/PYXLOGOTRANSPERENT.png";
import React, { useEffect } from "react";
import Button from "../../components/app2/Button";
import { useContextMenuApi } from "../../providers/ContextMenuProvider";
import { useSystemFunctions } from "../../providers/SystemFunctionsProvider";
import { useUserFunctions } from "../../providers/UserFunctionsProvider";
import { Inventory } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Dashboard(props) {
    const sysFunc = useSystemFunctions();
    const userFunc = useUserFunctions();
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (
                sysFunc.tabContainerApiRef.current.selectedTab.value === "dashboard" &&
                !document.getElementById("keybinds-manager")
            ) {
                if (event.code === "KeyG") {
                    sysFunc.addTabById({ value: "goods", category: "Inventory" });
                } else if (event.code === "KeyI") {
                    sysFunc.addTabById({ value: "itemCard", category: "Inventory" });
                } else if (event.code === "KeyP") {
                    sysFunc.addTabById({ value: "purchase", category: "Inventory" });
                } else if (event.code === "KeyS") {
                    sysFunc.addTabById({ value: "sales", category: "Inventory" });
                } else if (event.code === "KeyF") {
                    sysFunc.addTabById({ value: "proforma", category: "Inventory" });
                } else if (event.code === "KeyD") {
                    sysFunc.addTabById({ value: "stockOverview", category: "Inventory" });
                } else if (event.code === "KeyC") {
                    sysFunc.addTabById({ value: "accounts", category: "Accounting" });
                } else if (event.code === "KeyM") {
                    sysFunc.addTabById({ value: "statementOfAccount", category: "Accounting" });
                } else if (event.code === "KeyJ") {
                    sysFunc.addTabById({ value: "journalVoucher", category: "Accounting" });
                } else if (event.code === "KeyR") {
                    sysFunc.addTabById({ value: "receiptVoucher", category: "Accounting" });
                } else if (event.code === "KeyW") {
                    sysFunc.addTabById({ value: "paymentVoucher", category: "Accounting" });
                } else if (event.code === "KeyT") {
                    sysFunc.addTabById({ value: "trialBalance", category: "Accounting" });
                }
            }
        };

        window.addEventListener("keypress", handleKeyPress);

        return () => {
            window.removeEventListener("keypress", handleKeyPress);
        };
    }, []);
    return (
        <div className="flex h-[100%] w-full flex-col gap-8 overflow-y-auto p-3 ">
            <div className="flex w-full  flex-row items-stretch justify-around gap-2">
                <div className=" flex w-[50%] flex-row justify-center">
                    <div className="  cBorder2   w-[60%] rounded-2xl p-1 max-lg:w-[90%] ">
                        <img
                            draggable={false}
                            src={logo}
                            alt="Logo"
                            className="h-full rounded-xl bg-neutral-800 "
                        />
                    </div>
                </div>
                <div className=" cBorder2 rotate-[-35deg] rounded-full  p-[1px]  max-lg:rotate-0"></div>
                <div
                    className=" gradient-textx flex  w-[50%] flex-col items-center justify-center font-sans text-3xl font-bold max-lg:text-sm "
                    style={{ fontFamily: "sans-serif" }}
                >
                    <div className=" text-center">PYX TECHNOLOGIES</div>
                    <div className=" text-center">360&deg; BUSSINESS SOLUTION</div>
                </div>
            </div>
            <div className="flex h-full max-h-[35rem] w-full flex-row  max-lg:flex-col">
                <div className="flex h-full w-[50%] flex-col items-center gap-2  p-2 max-lg:w-full">
                    <Box
                        sx={{ backgroundColor: "system.secondaryBG" }}
                        className="bg-sys-secx flex w-[80%] flex-col gap-2 rounded-xl p-2"
                    >
                        <div className=" text-lg font-semibold">App :</div>
                        <div className="ml-3 flex flex-col gap-1">
                            <div>
                                <span className=" font-semibold">Version: </span>
                                {sysFunc.appVersion}
                            </div>
                        </div>
                    </Box>
                    <Box
                        sx={{ backgroundColor: "system.secondaryBG" }}
                        className="bg-sys-secx flex w-[80%] flex-col gap-2 rounded-xl p-2"
                    >
                        <div className=" text-lg font-semibold">User :</div>
                        <div className="ml-3 flex flex-col gap-1">
                            <div>
                                <span className=" font-semibold">User Name: </span>
                                {userFunc.user.name}
                            </div>
                            <div>
                                <span className=" font-semibold">Data Name: </span>
                                {userFunc.user.dataname}
                            </div>
                            {/* <div className=" font-semibold">Login History: Coming Soon</div> */}
                        </div>
                    </Box>
                    <Box
                        sx={{ backgroundColor: "system.secondaryBG" }}
                        className="bg-sys-secx flex w-[80%] flex-col gap-2 rounded-xl p-2"
                    >
                        <div className=" text-lg font-semibold">PC :</div>
                        <div className="ml-3 flex flex-col gap-1">
                            <div>
                                <span className=" font-semibold">System Name: </span>
                                {userFunc.user.pc_info.data.system_name}
                            </div>
                            <div>
                                <span className=" font-semibold">Model: </span>
                                {userFunc.user.pc_info.data.model}
                            </div>
                        </div>
                    </Box>
                    <Box
                        sx={{ backgroundColor: "system.secondaryBG" }}
                        className="bg-sys-secx flex w-[80%] flex-col gap-2 rounded-xl p-2"
                    >
                        <div className=" text-lg font-semibold">Licence : </div>
                        <div className="ml-3 flex flex-col gap-1">
                            <div>
                                <span className=" font-semibold">Types: </span>
                                {userFunc.user.pc_info.lic.toString()}
                            </div>
                        </div>
                    </Box>
                    <Box
                        sx={{ backgroundColor: "system.secondaryBG" }}
                        className="bg-sys-secx flex w-[80%] flex-col gap-2 rounded-xl p-2"
                    >
                        <div className="text-lg font-semibold">Shortcut Keys:</div>
                        <div
                            className="flex w-[20rem]  flex-col divide-y px-2"
                            style={{ maxHeight: "100px", overflowY: "auto" }}
                        >
                            <div className="flex justify-between font-semibold">
                                <div>Goods:</div>
                                <div>Key G</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>ItemCard:</div>
                                <div>Key I</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>Purchase:</div>
                                <div>Key P</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>Sales:</div>
                                <div>Key S</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>Proforma:</div>
                                <div>Key F</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>StockOverview:</div>
                                <div>Key D</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>Accounts:</div>
                                <div>Key C</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>StatementOfAccount:</div>
                                <div>Key M</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>JournalVoucher:</div>
                                <div>Key J</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>ReceiptVoucher:</div>
                                <div>Key R</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>PaymentVoucher:</div>
                                <div>Key W</div>
                            </div>
                            <div className="flex justify-between font-semibold">
                                <div>TrialBalance:</div>
                                <div>Key T</div>
                            </div>
                        </div>
                    </Box>
                </div>
                <div className="flex h-[100%] w-[50%] justify-center  p-2 max-lg:w-full">
                    <Box
                        sx={{
                            backgroundColor: "system.secondaryBG",
                        }}
                        className="bg-sys-secx flex h-full w-[80%] flex-col gap-2 rounded-xl p-2"
                    >
                        <div className="text-xl font-semibold">What's New:</div>
                        <div className="h-full max-h-[30rem] min-h-[20rem] overflow-y-auto rounded-lg">
                            <div className="flex flex-col gap-4 p-2">
                                {updateHistory.map((item, index) => (
                                    <Box
                                        sx={{
                                            backgroundColor: "system.primaryBG",
                                        }}
                                        key={index}
                                        className="flex flex-col gap-2 rounded p-2"
                                    >
                                        <div className="flex w-full items-center gap-4 text-xl font-semibold">
                                            <div>Version: {item.version} </div>
                                            <div>
                                                {item.version === sysFunc.appVersion
                                                    ? "( Current Version )"
                                                    : ""}
                                            </div>
                                            <div>{item.isMajor && "( Major Update )"}</div>
                                        </div>
                                        <div className="flex flex-col gap-2 pl-4 ">
                                            {item.description}
                                        </div>
                                    </Box>
                                ))}
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        </div>
    );
}
const updateHistory = [
    {
        version: "2.10.30",
        isMajor: false,
        description: (
            <>
                <div>1. New Barcode Mode in Purchase</div>
                <div>2. Automaticly Set Invoice Salesman By Account</div>
            </>
        ),
    },
    {
        version: "2.10.18",
        isMajor: true,
        description: (
            <>
                <div>1. Accounts Search Improvments</div>
                <div>2. Performance Inhancements</div>
                <div>3. Unused Account in Invoice Page are now hidden By Default</div>
                <div>
                    4. New Invoice Transaction, A Fully Dynamic Way To Add Expences and other
                    Transactions for the Invocie
                </div>
                <div>5. Updated Settings Take Effect Directly</div>
                <div>
                    6. Introducing Our New Trial Balance Presets, Now you can save your own filter
                    presets, soon comming to other pages!
                </div>
                <div>7. New Shortcut Key To Open Itemcard On Selected Line In Invoice Page</div>
                <div>8. Old and New Balance in Receipt and Payment Vouchers</div>
            </>
        ),
    },
    {
        version: "2.10.18",
        isMajor: true,
        description: (
            <>
                <div>1. Accounts Search Improvments</div>
                <div>2. Performance Inhancements</div>
                <div>3. Unused Account in Invoice Page are now hidden By Default</div>
                <div>
                    4. New Invoice Transaction, A Fully Dynamic Way To Add Expences and other
                    Transactions for the Invocie
                </div>
                <div>5. Updated Settings Take Effect Directly</div>
                <div>
                    6. Introducing Our New Trial Balance Presets, Now you can save your own filter
                    presets, soon comming to other pages!
                </div>
                <div>7. New Shortcut Key To Open Itemcard On Selected Line In Invoice Page</div>
                <div>8. Old and New Balance in Receipt and Payment Vouchers</div>
            </>
        ),
    },
    {
        version: "2.9.26",
        description: (
            <>
                <div>
                    1. Added Option To View Last Order Date and Last Order Quantity In Selected Item View
                    In The Invoice Pages
                </div>
            </>
        ),
    },
    {
        version: "2.9.19",
        description: (
            <>
                <div>
                    1. Activating Min And Max Quantity Warning ,Red Color Of Quantity If It's Less Than
                    Min And Yellow If It's More Than Max
                </div>
                <div>2. Group By In Items Search</div>
            </>
        ),
    },
    {
        version: "2.9.3",
        description: (
            <>
                <div>
                    1. Custom Posting Configurations, Introducing the ability to Fully Customize your
                    Posting Methods
                </div>
            </>
        ),
    },
    {
        version: "2.8.28",
        description: (
            <>
                <div>1. Resize Image In Items Search Modal</div>
                <div>2. Added Supplier Filter In Stock Overview</div>
            </>
        ),
    },
    {
        version: "2.8.25",
        description: (
            <>
                <div>
                    1. New "Accounts Page" with a new look and feel. Now you can view all your Accounts
                    in a treeview format.
                </div>
            </>
        ),
    },
    {
        version: "2.8.16",
        description: (
            <>
                <div>
                    1. Branch Transfers, Transfer items seamlessly between branches using our new branch
                    transfer page
                </div>
                <div>2. Performance And UI Improvments For Items Search Modal</div>
                <div>3. Ability to filter by Available Items in the Items Search</div>
            </>
        ),
    },
    {
        version: "2.8.10",
        description: (
            <>
                <div>
                    1. Full Serial Number tracking support for goods. Now you can add, edit, and view
                    serial Numbers
                </div>
            </>
        ),
    },
    {
        version: "2.8.5",
        description: (
            <>
                <div>
                    1. Introduction of the new "Keyboard Shortcuts" feature for quick access to all
                    tools. Navigate to the "Keyboard Shortcuts" tab in the top-right menu to view the
                    complete list of shortcuts.
                </div>
                <div>
                    2. New image field for goods. Usable in Invoices, Reports, Searching... , Right Click
                    On Image To Remove
                </div>
                <div>3. Performance enhancements.</div>
                <div>4. Quality of life improvements for accounting related pages.</div>
            </>
        ),
    },
];
