import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select as MuiSelect } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

function Select({
    options = [],
    value,
    onChange,
    label,
    id,
    menuValue,
    menuLabels,
    width = 150,
    size = "small",
    style = {},
    sx = {},
    allowNone = true,
    disabled = false,
    autoFocus = false,
    sRef = React.createRef(),
    name = "",
    onContextMenu,
}: {
    options?: any[];
    value?: any;
    onChange?: any;
    label?: any;
    id?: any;
    menuValue?: any;
    menuLabels?: any;
    width?: any;
    size?: any;
    style?: any;
    sx?: any;
    allowNone?: any;
    disabled?: any;
    autoFocus?: any;
    sRef?: any;
    name?: string;
    onContextMenu?;
}) {
    return (
        <FormControl sx={{ width: width, ...sx }} size={size} style={style}>
            <InputLabel
                sx={{
                    color: "input.text.secondary",
                }}
            >
                {label}
            </InputLabel>
            <MuiSelect
                name={name || id}
                autoFocus={autoFocus}
                disabled={disabled}
                value={value}
                onChange={onChange ?? ((e) => (sRef.current.value = e.target.value))}
                onContextMenu={onContextMenu ?? onContextMenu}
                label={label}
                id={id}
                inputRef={sRef}
                sx={{
                    "& .MuiOutlinedInput-input": {
                        color: "input.text.primary",
                    },
                }}
            >
                {allowNone && (
                    <MenuItem key={"None"} value={"NULL"}>
                        None
                    </MenuItem>
                )}
                {options.map((option) => (
                    <MenuItem key={option[menuValue] ?? option} value={menuValue === "" ? option : option[menuValue]}>
                        {menuLabels.map((label, idx) => {
                            return label === "" ? option : option[label] + " " + ([undefined, ""].includes(option[menuLabels[idx + 1]]) ? "" : " - ");
                        })}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
}

export default Select;
