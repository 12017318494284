import React, { createContext, useState, useContext, useMemo } from "react";

const LanguageContext: any = createContext<any>(1);

export const LanguageProvider = ({ children }) => {
    const [lang, setLanguage] = useState(languagePack(1)); // Default language is 'en' (English)

    const contextValue = useMemo(
        () => ({
            lang,
            langHandler: (e) => setLanguage(languagePack(e)),
        }),
        [lang]
    );
    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
    const context: {
        lang: LanguageContextType;
        langHandler: (e) => void;
    } = useContext(LanguageContext);
    if (!context) {
        throw new Error("useLanguage must be used within a LanguageProvider");
    }
    return context;
};

function languagePack(lang): LanguageContextType {
    if (lang === 1) {
        const t = {
            TOGGLE_BARCODE_MODE: "Toggle Barcode Mode",
            INVOICES_LIST: "Invoice List",
            ITEMS_MANAGEMENT: "Items Managment",
            INVOICE_PAYMENT: "Invoice Payment",
            PAY_INVOICE: "PAY INVOICE",
            BALANCE_QTY: "Balance Qty",
            TREE_VIEW: "ٍSwitch Tree View",
            "EQUITY_&_lONGTERM_DEBTS": "Equity & Longterm Debts",
            FIXED_ASSETS: "Fixed Assets",
            "INVENTORY&GOODS": "Inventory & Goods",
            "RECIEVABLES&PAYABLES": "Recievables & Payables",
            FINANCIAL_ACCOUNTS: "Financial Accounts",
            EXPENDITURES: "Expenditures",
            "INCOME&REVENUE": "Income & Revenue",

            "POST/ACC": "Post/Acc",
            ADD_ITEM: "Add Item",
            GROUP: "Group",
            PACK_QTY: "Pack Qty",
            SCALE_CONFIG: "Scale config",
            GOODS_SYNC: "Goods Sync",
            MULTI_EDIT: "Multi Edit",
            LABEL_EXPORT: "Label Export",
            INFORAMTION: "Information",
            SUBSCRIPTIONS: "Subscriptions",
            EMPLOYEES: "Employees",
            ITEM_CARD: "Item Card",
            COMING_SOON: "Coming Soon",
            STATEMENT_OF_ACC: "Statement Of Acc",
            ATTENDANCE: "Attendance",
            REPORT_BUILDER: "Report Builder",
            INVENTORY: "Inventory",
            SALES_MANAGEMENT: "Sales Management",
            PURCHASE_MANAGEMENT: "Purchase Management",
            ACCOUNTING: "Accounting",
            LANGUAGES: "Languages",
            LOGOUT: "Logout",
            HOME: "Home",
            SEARCH_ITEM: "Search Item",
            CREATE_NEW_ITEM: "Create New Item",
            JOURNAL_VOUCHER: "Journal Voucher",
            RECEIPT_VOUCHER: "Receipt Voucher",
            PAYMENT_VOUCHER: "Payment Voucher",
            lang: "en",
            CASH: "Cash",
            GOODS: "Goods",
            AUTOMATIC_ACCOUNT_CLOSING: "AUTOMATIC ACCOUNT CLOSING",
            ACCOUNTS_TO_CLOSE: "Accounts To Close",
            NO_INVOICE_FOUND: "No Invoice Found",
            ITEM_CODE: "Item Code",
            NEW_ITEM_CODE: "New Item Code",
            IS_TAXED: "Is Taxed",
            OPEN_ITEM_CARD_ON_CREATION: "Open Item Card On Creation",
            CREATE_ITEM: "Create Item ",
            ITEMCODE: "Itemcode",
            SEARCH: "Search",
            ITEM_ATTRIBUTES: "Item Attributes",
            ATTRIBUTES_SETUP: "Attributes Setup",
            CATEGORIES: "Categories",
            SETS: "SETS",
            BRANDS: "Brands",
            COLORS: "Colors",
            UNITS: "Units",
            FAMILIES: "Families",
            ADD_ATTRIBUTE: "Add Attribute",
            CREATE_NEW_ATTRIBUTE: "Create New Attribute",
            NEW_ATTRIBUTE: "New Attribute",
            CANCEL: "Cancel",
            APPLY: "Apply",
            BACK: "Back",
            PURCHASE: "Purchase",
            SALES: "Sales",
            PROFORMA: "Proforma",
            STATEMENT_OF_ITEM: "Statement Of Item",
            STOCK_OVERVIEW: "Stock Overview",
            STATISTCS: "Statistcs",
            ACCOUNTS: "Accounts",
            JOURNAL_VOUCHERS: "Journal Vouchers",
            RECEIPT_VOUCHERS: "Receipt Vouchers",
            PAYMENT_VOUCHERS: "Payment Vouchers",
            STATEMENT_OF_ACCOUNT: "Statement Of Account",
            TRIAL_BALANCE: "Trial Balance",
            REPORTS: "Reports",
            ITEM_NAME1: "Item Name 1",
            LIMIT_IN_MAIN_CURRENCY: "Limit In Main Currency",
            AUTO_ACCOUNT_CLOSING: "Auto Account Closing",
            SIMPLIFIED_VIEW: "Simplified View",
            ACTIVE_ACCOUNTS_ONLY: "Active Accounts Only",
            CREATE_NEW_ACCOUNT: "Create New Account",
            AUTO_CLOSE_ACOUNTS: "Auto Close Acounts",
            MERGE_ACCOUNTS: "Merge Accounts",
            CLIENT: "Client",
            SUPPLIER: "Supplier",
            EMPLOYES: "Employes",
            BANK: "Bank",
            STOCK: "Stock",
            CAPITAL: "Capital",
            EXPENSES: "Expenses",
            TAX: "Tax",
            ACCOUNT_ID: "Account No",
            NAME_1: "Name 1",
            OPEN_ACCOUNT_PROFILE_ON_CREATION: "Open AccountProfile On Creation",
            CREATE_ACCOUNT: "Create Account",
            TEL: "Tel",
            CLICK_FOR_MORE_INFORMATION: "Click For More Information",
            BACKUP: "Backup",
            DELETED_INVOICE: "Deleted_Invoice",
            BY: "BY",
            RESTORE: "RESTORE",
            CREATE_NEW_INVOICE: "CREATE NEW ",
            PRINT_INVOICE: "Print",
            TOGGLE_SELECTION_MODE: "Toggle Selection Mode",
            GENERAL_SETTINGS: "General Settings",
            DUPLICATE_INVOICE: "Duplicate",
            POST_TO_ACCOUNTING: "Post",
            GO_TO_ACCOUNTING: "Accounting",
            SALE_PERCENTAGE: "Sale Percentage",
            BEFORE_DISCOUNT: "Before Discount",
            TOTAL_TAX: "Total Tax",
            PAYMENT_AMOUNT: "Payment Amount",
            TOTAL_NET_COST: "Total Net Cos",
            TOTAL_NET_PRICE: "Total Net Price",

            INVOICE_DATE: "Invoice Date",
            VALUE_DATE: "Value Date",
            CURRENCY_1: "Currency 1",
            CURRENCY_2: "Currency 2",
            RATE_1: "Rate 1",
            RATE_2: "Rate 2",
            BRANCH: "Branch",
            S_MAN: "S.Man",
            JOB: "Job",
            DISCOUNT: "Disc %",
            NOTES: "Notes",
            CREATED_BY: "Created By",
            DISCOUNT_AMOUNT: "Disc Amount",

            OPPOSITE_ACCOUNT: "Opposite Account",
            TAX_ACCOUNT: "Tax Account",
            OPPOSITE_ACCOUNT_NAME: "Opposite Account Name",
            TAX_ACCOUNT_NAME: "Tax Account Name",
            OPP_RATE: "Opp Rate",
            TAX_RATE: "Tax Rate",
            CREDIT_ACCOUNT_NAME: "Credit Account Name",
            DEBIT_ACCOUNT_NAME: "Debit Account Name",

            TYPE: "Type",
            NUM: "Num",

            DELETE_INVOICE: "Delete Invoice",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_INVOICE_AND_ACCOUNTING_DATA:
                "Are You Sure You Want To Delete Invoice and Accounting Data",
            DELETE_SELECTED_ITEMS: "Delete Selected Items",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_LINES: "Are You Sure You Want To Delete Lines",
            DUPLICATE_TO: "Duplicate To",

            FLIP_CURRENCIES: "Flip Currencies",
            FROM: "FROM",
            TO: "TO",
            AUTOMATICALLY_CONVERT_ALL_INVOICE_ITEMS_PRICE:
                "Automatically Convert All Invoice Items Price",
            ALL_ITEMS_PRICE_WILL_BE_DIVIDED_BY_RATE_1_THEN_MULTIPLIED_BY_RATE_2:
                "All Items Price Will Be Divided By Rate 1 Then Multiplied By Rate 2",
            ITEM_NAME: "Item Name",
            PRICE: "Price",
            PERCENT: "Percent",
            PRICE_BF_TAX: "Price Bf. Tax",
            FINAL_PRICE: "Final Price",
            SALE_PRICE: "Sale Price",
            RATE: "Rate",
            ADJUSTMENT: "Adjustment",

            SETTINGS: "Settings",
            USER: "User",
            TABLE_CONFIGURATION: "Table Configuration",
            DEFAULT_CONFIGURATION: "Default Configuration",
            DEFAULT_SALE_PRICE: "Default Sale Price",
            DEFAULT_INV_TYPE: "Default Inv Type",
            DEFAULT_CREDIT_ACCOUNT: "Default Credit Account",
            DEFAULT_OPP_ACCOUNT: "Default Opp Account",
            DEFAULT_DEBIT_ACCOUNT: "Default Debit Account",
            DEFAULT_TAX_ACCOUNT: "Default Tax Account",

            ACCOUNT_NAME: "Account Name",
            CURRENCY: "Currency",
            FILTERS: "Filters",
            SELECTED_VOUCHER_TYPES: "Selected Voucher Types",
            SELECTED_BRANCHES: "Selected Branches:",
            SELECTED_SALESMAN: "Selected SalesMan",
            SALES_MAN: "Sales Man",
            SELECTED_JOBS: "Selected Jobs",
            JOBS: "Jobs",
            CLEAR_DATA: "Clear Data",
            GET_DATA: "Get Data",

            CREATE_NEW_VOUCHER: "Create New Voucher",
            NO_VOUCHER_FOUND: "No Voucher Found",
            LINE: "Line",
            AMOUNT: "Amount",
            CUR: "Cur",
            DB_CR: "DB.CR",
            NOTE: "Note",
            BALANCE: "Balance",
            OLD_BALANCE: "Old Balance",
            NEW_BALANCE: "New Balance",

            VOUCHER_DATE: "Voucher Date",
            EDIT_TRANSACTION_TABLE: "Edit Transaction Table",

            PRINT_VOUCHER: "Print Voucher",
            DELETE_VOUCHER: "Delete Voucher",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_VOUCHER_DATA:
                "Are You Sure You Want To Delete Voucher Data?",
            CONFIRM: "Confirm",
            GO_TO_INVOICE: "Go To Invoice",

            TOTAL_DEBIT: "Total Debit",
            TOTAL_CREDIT: "Total Credit",

            EDIT_TRANSACTION: "Edit Transaction",
            SELECTED: "Selected",
            A_C_Amount: "A.C. Amount",
            A_C_Code: "A.C. Code",
            AUTO_FILL: "Auto Fill",
            DB_TOTAL: "DB Total",
            CR_TOTAL: "CR Total",

            AUTO_FILL_SELECTED_ROW: "Auto Fill Selected Row",
            RESET: "Reset",

            CREATE_NEW_SUPPLIER: "Create New Supplier",
            CREATE_NEW_CLIENT: "Create New Client",
            DEBIT_ACCOUNT: "Debit Account",
            CREDIT_ACCOUNT: "Credit Account",

            DEBIT_RATE: "Debit Rate",
            CREDIT_RATE: "Credit Rate",

            ORIGINAL_NUMBER: "Original Number",
            ITEMNAME1: "itemname1",
            QTY: "Qty",
            COST: "Cost",
            SAME_RATE: "Same Rate",
            GO_TO_NEW_INVOICE: "Go To New Invoice",
            ALL_ITEMS: "All Items",
            SELECTED_LINES: "Selected Lines",
            AUTOMATIC_PAYMENT: "Automatic Payment",
            START_DATE: "Start Date",
            END_DATE: "End Date",
            VOUCHER_TYPES: "Voucher Types",
            BRANCHES: "Branches",
            CR_AMOUNT: "Cr Amount",
            DATE: "Date",
            DB_AMOUNT: "Db Amount",
            LINE_RATE: "Line Rate",
            INV_AMOUNT_1: "Inv Amount 1",
            INV_TAX_1: "Inv Tax 1",
            INV_AMOUNT_2: "Inv Amount 2",
            INV_TAX_2: "Inv Tax 2",
            SMAN: "Sman",
            BR: "Br",
            INV_DATE: "Inv Date",
            INV_TYPE: "Inv Type",
            INV_NUM: "Inv Num",
            EXP: "Exp",
            DISC: "Disc",
            ALL: "All",

            ACCOUNTS_FILTERS: "Accounts Filters",
            FIND: "Find",
            OTHER_FILTERS: "Other Filters",

            VALUE_1: "Value 1",
            VALUE_2: "Value 2",
            VALUE_3: "Value 3",
            VALUE_4: "Value 4",
            FILTER_1: "Filter 1",
            FILTER_2: "Filter 2",
            FILTER_3: "Filter 3",
            FILTER_4: "Filter 4",

            ACCOUNT_PROFILE: "Account Profile",
            ACCOUNT_NAME_1: "Account Name 1",
            ACCOUNT_NAME_2: "Account Name 2",
            ACTIVE: "Active",
            LIMIT: "Limit",
            COUNTRY: "Country",
            ADDRESS_1: "Address 1",
            ADDRESS_2: "Address 2",
            PROVINCE: "Province",
            CITY: "City",
            STREET: "Street",
            BUILDING: "Building",
            FLOOR: "Floor",
            TAX_NUMBER: "Tax Number",
            PHONE_1: "Phone 1",
            PHONE_2: "Phone 2",
            EMAIL: "Email",
            WEBSITE: "Website",
            PACK_NAME: "Pack Name",
            TOTAL_QTY: "Total Qty",
            PACK_PRICE: "Pack Price",
            TOTAL_PRICE: "Total Price",
            TOTAL_DISC: "Total Disc",
            TOTAL_EXP: "Total Exp",
            TTC: "TTC",
            TOTAL_TTC: "Total TTC",
            Pack_NAME: "Pack Name",
            COLOR: "Color",
            PIC: "Pic",
            WEB_URL: "Web Url",
            SALE_1: "Sale 1",
            SALE_2: "Sale 2",
            SALE_3: "Sale 3",
            SALE_4: "Sale 4",
            SALE_1_TTC: "Sale 1 TTC",
            SALE_2_TTC: "Sale 2 TTC",
            SALE_3_TTC: "Sale 3 TTC",
            SALE_4_TTC: "Sale 4 TTC",
            EXTRA_CHARGE: "Extra Charge",
        };
        return t;
    } else if (lang === 2) {
        return {
            TOGGLE_BARCODE_MODE: "تبديل وضع الباركود",
            INVOICES_LIST: "لائحة الفواتير",
            ITEMS_MANAGEMENT: "تعديلات الأصناف",
            PAY_INVOICE: "دفع الفاتورة",
            INVOICE_PAYMENT: "دفع الفاتورة",
            BALANCE_QTY: "معدل الكمية",
            TREE_VIEW: "تبديل العرض",
            "POST/ACC": "ترحيل/الحسابات",
            ADD_ITEM: "إضافة صنف",
            GROUP: "المجموعة",
            PACK_QTY: "كمية العبوة",
            SCALE_CONFIG: "انشاء ميزان",
            GOODS_SYNC: "مزامنة الأصناف",
            MULTI_EDIT: "تعديل متعدد",
            LABEL_EXPORT: "طباعة ملصقات",
            INFORAMTION: "معلومات",
            SUBSCRIPTIONS: "الاشتراكات",
            EMPLOYEES: "الموظفين",
            ITEM_CARD: "بطاقة الصنف",
            COMING_SOON: "قريباً",
            STATEMENT_OF_ACC: "كشف حساب",
            ATTENDANCE: "الحضور",
            REPORT_BUILDER: "بناء التقرير",
            INVENTORY: "المخزون",
            SALES_MANAGEMENT: "إدارة المبيعات",
            PURCHASE_MANAGEMENT: "إدارة المشتريات",
            ACCOUNTING: "المحاسبة",
            LANGUAGES: "اللغات",
            LOGOUT: "تسجيل الخروج",
            HOME: "الرئيسية",
            JOURNAL_VOUCHER: "سند القيد",
            RECEIPT_VOUCHER: "سند القبض",
            PAYMENT_VOUCHER: "سند الدفع",
            lang: "ar",
            CASH: "النقد",
            GOODS: "الأصنـــاف",
            CREATE_NEW_ITEM: "انشاء صنف جديد",
            NEW_ITEM_CODE: "كود صنف الجديد",
            IS_TAXED: "مع الضريبة",
            OPEN_ITEM_CARD_ON_CREATION: "فتح ملف الصنف عند انشاء الصنف ",
            CREATE_ITEM: "انشاء الصنف",
            SEARCH_ITEM: "بحث عن صنف",
            ITEMCODE: "كود الصنف",
            SEARCH: "بحث",
            ITEM_ATTRIBUTES: "تصنيف الأصناف",
            ATTRIBUTES_SETUP: "انشاء مجموعات",
            CATEGORIES: "مجموعة",
            SETS: "فئه",
            BRANDS: "الماركة",
            COLORS: "اللون",
            UNITS: "الوحده",
            FAMILIES: "العائله",
            ADD_ATTRIBUTE: "اضافة ",
            CREATE_NEW_ATTRIBUTE: "انشاء مجموعة جديدة",
            NEW_ATTRIBUTE: "مجموعة جديدة",
            CANCEL: "اغلاق",
            APPLY: "تأكيد",
            BACK: "رجوع",
            PURCHASE: "المشتريات",
            SALES: "المبيعات",
            PROFORMA: "فاتورة طلبية",
            STATEMENT_OF_ITEM: "كشف عن صنف",
            STOCK_OVERVIEW: "المخزن العام",
            STATISTCS: "احصائيات",
            ACCOUNTS: "الحسابات",
            JOURNAL_VOUCHERS: "قيود محاسبية",
            RECEIPT_VOUCHERS: "سندات القبض",
            PAYMENT_VOUCHERS: "سندات الدفع",
            STATEMENT_OF_ACCOUNT: "كشف حساب",
            TRIAL_BALANCE: "ميزان المراجعة",
            REPORTS: "تقارير",
            AUTOMATIC_ACCOUNT_CLOSING: "اغلاق الحسابات تلقائياً",
            ACCOUNTS_TO_CLOSE: "الحسابات لإغلاقها",
            LIMIT_IN_MAIN_CURRENCY: "الحد بالعملة الرئيسية",
            AUTO_ACCOUNT_CLOSING: "Auto Account Closing",
            SIMPLIFIED_VIEW: "عرض مبسط",
            ACTIVE_ACCOUNTS_ONLY: "الحسابات النشطة فقط",
            CREATE_NEW_ACCOUNT: "انشاء حساب جديد",
            AUTO_CLOSE_ACOUNTS: "اغلاق الحسابات تلقائيا",
            MERGE_ACCOUNTS: "دمج حساب",
            CLIENT: "الزبائن",
            SUPPLIER: "موردين",
            EMPLOYES: "موظفين",
            BANK: "بنوك",
            STOCK: "مخزون",
            CAPITAL: "رأس المال",
            EXPENSES: "المصاريف",
            TAX: "الضريبة",
            ACCOUNT_ID: "رقم الحســـاب",
            NAME_1: "الاســـــم",
            OPEN_ACCOUNT_PROFILE_ON_CREATION: "فتح ملف الحساب عند انشاء الحساب",
            CREATE_ACCOUNT: "انشاء الحساب",
            TEL: "هاتف",
            CLICK_FOR_MORE_INFORMATION: "انقر لمزيد من المعلومات",
            BACKUP: "نسخه إحتياطية",
            DELETED_INVOICE: "الفاتورة المحذوفة",
            BY: "بواسطة",
            RESTORE: "إسترجاع",
            CREATE_NEW_INVOICE: "انشاء فاتورة جديدة",
            PRINT_INVOICE: "طباعة الفاتورة",
            TOGGLE_SELECTION_MODE: "تبديل وضع التحديد",
            GENERAL_SETTINGS: "إعدادات عامــة",
            DUPLICATE_INVOICE: "تكرار الفاتورة",
            POST_TO_ACCOUNTING: "ترحيل الى المحاسبة",
            GO_TO_ACCOUNTING: "الذهاب الى القيد",
            SALE_PERCENTAGE: "تسعير الاصناف",
            BEFORE_DISCOUNT: "القيمة قبل الحسم",
            TOTAL_TAX: "مجموع الضريبة",
            PAYMENT_AMOUNT: "مجموع الفاتورة",
            TOTAL_NET_COST: "إجمالي التكلفة الصافية",
            TOTAL_NET_PRICE: "إجمالي السعر الصافي",

            INVOICE_DATE: "تاريخ الفاتورة",
            VALUE_DATE: "تاريخ استحقاق الفاتورة",
            CURRENCY_1: "العملة 1",
            CURRENCY_2: "العملة 2",
            RATE_1: "سعر الصرف 1",
            RATE_2: "سعر الصرف 2",
            BRANCH: "الفرع",
            S_MAN: "البائع",
            JOB: "الوظيفة",
            DISCOUNT: "حسم",
            NOTES: "الشرح",
            CREATED_BY: "إنشاء بواسطة",
            DISCOUNT_AMOUNT: "قمة الحسم",

            OPPOSITE_ACCOUNT: "الحساب المرادف",
            TAX_ACCOUNT: "حساب الضرائب",
            OPPOSITE_ACCOUNT_NAME: "اسم الحساب المرادف",
            TAX_ACCOUNT_NAME: "اسم حساب الضرائب",
            OPP_RATE: "سعر صرف المرادف",
            TAX_RATE: "سعر صرف الضرائب",
            CREDIT_ACCOUNT_NAME: "اسم الحساب المدين",
            DEBIT_ACCOUNT_NAME: "اسم الحساب الدائن",
            TYPE: "نوع المستند",
            NUM: "الرقم",
            DELETE_INVOICE: "الغاء الفاتورة",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_INVOICE_AND_ACCOUNTING_DATA:
                "هل أنت متأكد أنك تريد حذف بيانات الفاتورة والمحاسبة",
            DELETE_SELECTED_ITEMS: "الغاء الأسطر المحددة",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_LINES: "هل أنت متأكد أنك تريد حذف السطور",
            DUPLICATE_TO: "تكرار الى",

            FLIP_CURRENCIES: "عكس العملة",
            FROM: "من",
            TO: "الى",
            AUTOMATICALLY_CONVERT_ALL_INVOICE_ITEMS_PRICE: "تحويل أسعار جميع بنود الفاتورة تلقائيًا",
            ALL_ITEMS_PRICE_WILL_BE_DIVIDED_BY_RATE_1_THEN_MULTIPLIED_BY_RATE_2:
                "سيتم تقسيم أسعار جميع العناصر على السعر 1 ثم ضربها في السعر 2",
            ITEM_NAME: "اسم الصنف",
            PRICE: "السعر",
            PERCENT: "نسبه مئويه",
            PRICE_BF_TAX: "السعر قبل الضربية",
            FINAL_PRICE: "السعر النهائي",
            SALE_PRICE: "سعر البيع",
            RATE: "سعر الصرف",
            ADJUSTMENT: "تعديل",

            SETTINGS: "اعدادات",
            USER: "المستخدم",
            TABLE_CONFIGURATION: "تكوين الجدول",
            DEFAULT_CONFIGURATION: "التكوين الافتراضي",
            DEFAULT_SALE_PRICE: "سعر البيع الافتراضي",
            DEFAULT_INV_TYPE: "الفواتير الإفتراضية",
            DEFAULT_CREDIT_ACCOUNT: "حساب الدائن الإفتراضي",
            DEFAULT_OPP_ACCOUNT: "حساب المرادف الإفتراضي",
            DEFAULT_DEBIT_ACCOUNT: "حساب المدين الإفتراضي",
            DEFAULT_TAX_ACCOUNT: "حساب الضريبة الإفتراضي",

            ACCOUNT_NAME: "اسم الحساب",
            CURRENCY: "العملة",
            FILTERS: "فلتر",
            SELECTED_VOUCHER_TYPES: "انواع السندات المحددة",
            SELECTED_BRANCHES: "اختيار الفرع:",
            SELECTED_SALESMAN: "اختيار البائع",
            SALES_MAN: "البائع",
            SELECTED_JOBS: "اختيار الوظيفة",
            JOBS: "الوظيفة",
            CLEAR_DATA: "محو البيانات",
            GET_DATA: "جلب الكشف",
            CREATE_NEW_VOUCHER: "انشاء سند جديد",
            NO_VOUCHER_FOUND: "سند غير موجود",
            LINE: "السطر",
            AMOUNT: "القيمة",
            CUR: "العملة",
            DB_CR: "DB.CR",
            NOTE: "الشرح",
            BALANCE: "الرصيد",

            OLD_BALANCE: "الرصيد القديم",
            NEW_BALANCE: "الرصيد الجديد",

            VOUCHER_DATE: "تاريخ السند",
            EDIT_TRANSACTION_TABLE: "تعديل جدول الحركات",

            PRINT_VOUCHER: "طباعة السند",
            DELETE_VOUCHER: "الغاء السند",
            ARE_YOU_SURE_YOU_WANT_TO_DELETE_VOUCHER_DATA: "هل أنت متأكد أنك تريد حذف بيانات السند؟",
            CONFIRM: "تأكيد",
            GO_TO_INVOICE: "الذهاب الى الفاتورة",
            TOTAL_DEBIT: "مجموع المدين",
            TOTAL_CREDIT: "مجموع الدائن",

            EDIT_TRANSACTION: "تعديل الحركة",
            SELECTED: "الحساب المختار",
            A_C_Amount: "القيمة بعملة الحساب",
            A_C_Code: "عملة الحساب",

            DB_TOTAL: "مجموع المدين",
            CR_TOTAL: "مجموع الدائن",
            AUTO_FILL: "تعبئة تلقائية",

            AUTO_FILL_SELECTED_ROW: "تعبئة تلقائية للسطر المحدد",

            RESET: "إعادة تعيين",
            NO_INVOICE_FOUND: "لا يوجد فاتورة",

            CREATE_NEW_SUPPLIER: "انشاء مورد جديد",
            CREATE_NEW_CLIENT: "انشاء زبون جديد",
            DEBIT_ACCOUNT: "حساب المدين",
            CREDIT_ACCOUNT: "حساب الدائن",

            DEBIT_RATE: "سعر الصرف للمدين",
            CREDIT_RATE: "سعر الصرف للدائن",
            ITEM_CODE: "كود الصنف",
            COST: "التكلفة",
            ORIGINAL_NUMBER: "الرقم الأصلي",
            ITEM_NAME1: "اسم الصنف",
            QTY: "الكمية",

            SAME_RATE: "نفس سعر الصرف ",
            GO_TO_NEW_INVOICE: "الإنتقال الى فاتورة جديدة",
            ALL_ITEMS: "كل الأصناف",
            SELECTED_LINES: "الأسطر المحددة",
            AUTOMATIC_PAYMENT: "الدفع اتوماتيكياً",
            START_DATE: "من تاريخ",
            END_DATE: "الى تاريخ",
            VOUCHER_TYPES: "نوع السند",
            BRANCHES: "الفروع",
            CR_AMOUNT: "قيمة الدائن",
            DATE: "التاريخ",
            DB_AMOUNT: "قيمة المدين",
            LINE_RATE: "سطر سعر الصرف",
            INV_AMOUNT_1: "INV_AMOUNT_1",
            INV_TAX_1: "INV_TAX_1",
            INV_AMOUNT_2: "INV_AMOUNT_2",
            INV_TAX_2: "INV_TAX_2",
            SMAN: "البائع",
            BR: "الفرع",
            INV_DATE: "تاريخ الفاتورة",
            INV_TYPE: "نوع الفاتورة",
            INV_NUM: "رقم الفاتورة",
            EXP: "مصروف",
            DISC: "حسم",
            ALL: "الكل",
            ACCOUNTS_FILTERS: "فلتر الحسابات",
            FIND: "جلب",
            OTHER_FILTERS: "فلاتر اخرى",
            VALUE_1: "رقم الحساب 1",
            VALUE_2: "رقم الحساب 2",
            VALUE_3: "رقم الحساب 3",
            VALUE_4: "رقم الحساب 4",
            FILTER_1: "فلتر 1",
            FILTER_2: "فلتر 2",
            FILTER_3: "فلتر 3",
            FILTER_4: "فلتر 4",

            ACCOUNT_PROFILE: "ملف الحساب",
            ACCOUNT_NAME_1: "اسم الحساب 1",
            ACCOUNT_NAME_2: "اسم الحساب 2",
            ACTIVE: "نشط",
            LIMIT: "الحد",
            COUNTRY: "البلد",
            ADDRESS_1: "العنوان 1",
            ADDRESS_2: "العنوان 2",
            PROVINCE: "المحافظة",
            CITY: "المدينة",
            STREET: "الشارع",
            BUILDING: "المبنى",
            FLOOR: "الطابق",
            TAX_NUMBER: "الرقم الضريبي",
            PHONE_1: "الهاتف 1",
            PHONE_2: "الهاتف 2",
            EMAIL: "البريد الإلكتروني",
            WEBSITE: "الموقع الإلكتروني",
            PACK_NAME: "",
            TOTAL_QTY: "مجموع الكمية",
            PACK_PRICE: "ٍسعر المجموعة",
            TOTAL_PRICE: "المجموع",
            Pack_NAME: "اسم المجموعة",
            TOTAL_DISC: "مجموع الحسم",
            TOTAL_EXP: "مجموع المصاريف",
            TTC: "مجموع مع الضريبة",
            TOTAL_TTC: "مجموع مع الضريبة",
            COLOR: "اللون",
            PIC: "صورة",
            WEB_URL: "عنوان او موقع",
            SALE_1: "السعر الاول",
            SALE_2: "السعر الثاني",
            SALE_3: "السعر الثالث",
            SALE_4: "السعر الرابع",
            SALE_1_TTC: "السعر الاول مع الضريبة",
            SALE_2_TTC: "السعر الثاني مع الضريبة",
            SALE_3_TTC: "السعر الثالث مع الضريبة",
            SALE_4_TTC: "السعر الرايع مع الضريبة",
            EXTRA_CHARGE: "تكلفة اضافية",

            "EQUITY_&_lONGTERM_DEBTS": "الأصول الثابتة والخصوم الطويلة الأجل",
            FIXED_ASSETS: "الأصول الثابتة",

            "INVENTORY&GOODS": "المخزون والبضائع",
            "RECIEVABLES&PAYABLES": "المدينون والدائنون",
            FINANCIAL_ACCOUNTS: "الحسابات المالية",

            EXPENDITURES: "المصروفات",
            "INCOME&REVENUE": "الإيرادات",
        };
    }
}
type LanguageContextType = typeof languagePack | any;
