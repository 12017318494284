import React, { createContext, ReactNode, useContext } from "react";
import { useAlert } from "./AlertProvider";
import { TTabsContainerApiRef } from "../components/App/NavTabs/TabsContainer";
import { apiParams } from "./typs";

// Define the types for your context
type TAccountFunctions = {
    fetchData: (params: TFetchDataParamas) => void;
    editData: (params: TEditDataParamas) => void;
    createAccount: (params: TCreateAccountParamas) => void;
    maxId: (any) => void;
    trialBalance: (params: TTrialBalanceParams) => void;
    getBalance: (params: TGetBalanceParms) => void;
    selectTrialBalancePresets: (params: apiParams<object>) => void;
    editTrialBalancePreset: (params: apiParams<{ id; field; value }>) => void;
    deleteTrialBalancePreset: (params: apiParams<{ id }>) => void;
    insertTrialBalancePreset: (params: apiParams<{ name }>) => void;
};

// Create a context with default values
const AccountFunctionsContext = createContext<TAccountFunctions | undefined>(undefined);

// Custom hook to use the context
export const useAccountFunctions = () => {
    const context = useContext(AccountFunctionsContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

interface AccountFuncProviderProps {
    children: ReactNode;
    tabContainerApiRef: React.MutableRefObject<TTabsContainerApiRef>;
}

export const AccountsFuncProvider: React.FC<AccountFuncProviderProps> = ({
    children,
    tabContainerApiRef,
}) => {
    const alert = useAlert();
    const value: TAccountFunctions = {
        fetchData: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/get_accounts/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        editData: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/update_account/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        maxId: ({ group_id, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/max_id/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, { group_id, value, confirm }, false);
        },
        trialBalance: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/trial_balance/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        createAccount: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/create_new_account/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, true);
        },
        getBalance: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/get_balance/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        selectTrialBalancePresets: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/select_trial_balance_presets/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        editTrialBalancePreset: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/edit_trial_balance_preset/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        deleteTrialBalancePreset: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/delete_trial_balance_preset/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        insertTrialBalancePreset: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "accounts/insert_trial_balance_preset/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
    };

    return <AccountFunctionsContext.Provider value={value}>{children}</AccountFunctionsContext.Provider>;
};

export type TFetchDataParamas = apiParams<{
    filters?: any;
    active_only;
}>;
export type TEditDataParamas = apiParams<{
    id;
    field;
    value;
    confirm;
}>;

export type TTrialBalanceParams = apiParams<{
    startDate;
    endDate;
    account_id;
    filters;
}>;
export type TCreateAccountParamas = apiParams<{
    id;
    name;
    currency;
}>;
export type TGetBalanceParms = apiParams<{
    account_id: string;
    filters: string[];
}>;
