import { useRef } from "react";
import Button from "../../app2/Button";
import Modal from "../../app2/Modal";
import TextField2 from "../../app2/TextField2";

interface TokenModalProps {
    show: boolean;
}

export default function TokenModal(props: TokenModalProps) {
    const tokenRef = useRef<HTMLInputElement>(null);

    return (
        <Modal
            title={"No Token Found"}
            width="35rem"
            height="25rem"
            show={props.show}
            setshow={() => {}}
        >
            <div className="flex h-full w-full flex-col justify-between gap-4 p-2">
                <div className="flex w-full flex-col gap-2">
                    <div className=" text-center text-lg font-semibold">
                        Welcome To PYX Backoffice Web App, Please Enter The Token Provided By PYX
                        Technologies
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className=" font-semibold">For More Info Please Contact:</div>
                    <div className="flex flex-col gap-2 pl-3">
                        <div className="flex gap-2">
                            <div>📧</div>
                            <a href="mailto:contact@pyxtechsolutions.com">
                                contact@pyxtechsolutions.com
                            </a>
                        </div>
                        <div className="flex gap-2">
                            <div>☎️</div>
                            <a href="tel:+961 76 918 291">+961 76 918 291</a>
                        </div>
                    </div>
                </div>
                <div className="flex w-full flex-row items-center justify-center">
                    <TextField2 label="Token" inputRef={tokenRef} />
                </div>
                <div className="flex justify-end">
                    <Button
                        onClick={() => {
                            const token = tokenRef.current?.value;
                            if (token) {
                                const serversData = JSON.parse(localStorage.getItem("serversData"));
                                serversData["selectedServer"]["token"] = token;
                                localStorage.setItem("serversData", JSON.stringify(serversData));
                                window.location.reload();
                            }
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
